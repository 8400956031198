import {Controller} from 'stimulus';
import multi from 'multi.js/dist/multi-es6.min.js';
import 'multi.js/dist/multi.min.css';

export default class extends Controller {
  static targets = [ 'select' ]

  connect () {
    multi(this.selectTarget, {
      "enable_search": true,
      "search_placeholder": "Search...",
      "non_selected_header": 'Not assigned',
      "selected_header": 'Assigned',
      "limit": -1,
      "limit_reached": function () {},
    });
  }
}
