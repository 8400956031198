import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = [ 'name' ]

  connect() {
    console.log('Hello from Stimulus', this.element);
  }

  log () {
    console.log(this.nameTarget.value, this.data.get("value"));
  }

  onEnterKey (e) {
    if (e.keyCode === 13) {
      this.log();
    }
  }
}
