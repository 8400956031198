import { Application } from 'stimulus'
import Flatpickr from 'stimulus-flatpickr'
import {  Modal } from "tailwindcss-stimulus-components"

import { definitionsFromContext } from 'stimulus/webpack-helpers'
const application = Application.start()
const context = require.context('../controllers/', true, /\.js$/)
application.load(definitionsFromContext(context))
application.register('flatpickr', Flatpickr)
application.register('modal', Modal)
