import {Controller} from 'stimulus';
const className = 'bg-red-400';
export default class extends Controller {

  loadWod (e) {
    e.preventDefault();
    const items = document.querySelectorAll('[data-controller="wod-day"]')
    items.forEach(node => {
      node.classList.remove(className);
    });
    this.element.classList.add(className)
    fetch(this.data.get("url")+'?short=true')
      .then(response => response.text())
      .then(html => {
        this.container.innerHTML = html
    })
  }

  get container() {
    const elem = document.querySelector("#wod_data")
    return elem
  }
}
