import {Controller} from 'stimulus';
import emitter from '../vendor/nano-events';

const templateGenerator = (type, title, message) => {
    const typeClass = (function(type) {
      switch(type) {
        case 'error':
        case 'danger':
          return 'red';
        case 'notice':
        case 'info':
          return 'blue';
        case 'warning':
        case 'alert':
          return 'yellow';
        default:
          return 'green';
      }
    })(type);

  return (
    `<div class="bg-${typeClass}-100 border-l-4 border-${typeClass}-500 text-${typeClass}-700 p-4 animated fadeOut delay-5s mb-5 z-50" role="alert">
    <p class="font-bold">${title}</p>
    <p>${message}</p>
    </div>`
  )
}
export default class extends Controller {
  static targets = [ 'template' ]

  connect () {
    this.unbind = emitter.on('alert', (type, title, message) => {
      const template = templateGenerator(type, title, message);
      const node = document.createElement('div');
      node.innerHTML = template;
      this.element.appendChild(node);
      node.addEventListener('animationend', function() {
        node.remove();
      })
    })
  }

  disconnect () {
    this.unbind();
  }
}
