import {Controller} from 'stimulus';
import emitter from '../vendor/nano-events';
window.emitter = emitter;

const WEEK_LIMIT = 12;
export default class extends Controller {
  static targets = ['template', 'add_week'];

  getSelectedColumn () {
    const colIndex = document.querySelector("[data-calendar-column]")
    return colIndex.dataset.calendarColumn;
  }

  addWeek (e) {
    e.preventDefault();
    const weeks = document.querySelectorAll(".week").length;
    const wods = document.querySelectorAll(".wod").length-1;
    const colIndex = this.getSelectedColumn();
    if (weeks < WEEK_LIMIT) {
      let content = this.templateTarget.innerHTML.replace(/NEW_WEEK/g, weeks +1);
        content = content.replace(/@(.*?)@/g, function(a, b){
            const newId = wods + parseInt(b);
            return newId;
        })
      this.add_weekTarget.insertAdjacentHTML('beforebegin', content);
      console.log("selected col", colIndex)
      if (colIndex != '0') {
        const cells = document.querySelectorAll(
          '[data-day-day="' + colIndex + '"]',
        );
        cells.forEach(node => {
          node.classList.add('col-selected');
        });
      }
    } else {
       emitter.emit('alert', 'error', 'Error!', 'This plan is already too long, please consider create new one')
    }
  }

  removeWeek (e) {
    e.preventDefault();
    const weeks = document.querySelectorAll(".week");
    const last = weeks[weeks.length - 1];
    last.remove();
  }
}
