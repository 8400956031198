import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = [ 'menu' ]

  toggle () {
    this.menuTarget.classList.toggle('show');
    this.menuTarget.classList.toggle('visible','invisible');
  }
}
