// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('../stylesheets/application.scss');
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
import 'vendor/nano-events';
import 'vendor/stimulus';
import 'flatpickr/dist/themes/dark.css'
// import 'multijs/dist/multi.min.css';
// import "multijs/dist/multi.min.js";

var Trix = require("trix");
/* what the newly created button does */
/* insert the button visual in the default toolbar */
addEventListener("trix-initialize", function(event) {
    var item = document.createElement("div");   // Create a <button> element
    item.innerHTML =`
    <div><b>Warm up:</b></div><br>
    <div><b>Strength/technique:</b></div><br>
    <div><b>Wod:</b></div><br>
    <div><b>Cooldown:</b></div><br>
    `;
    var editor = document.querySelector("trix-editor");
    if (editor.classList.contains('editor-result')) {

    } else {
      if(editor.innerHTML.length == 0) {
        editor.appendChild(item)
      }
    }
})
document.addEventListener('turbolinks:load', () => {
  const elements = document.querySelectorAll('.flash_message')
  elements.forEach( node => {
      node.addEventListener('animationend', function() {
        node.remove();
      })
  })
});
