import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['wod'];

  removePreviousSelected() {
    const elements = document.querySelectorAll('.day');
    const wods = document.querySelectorAll('.day_selected');
    elements.forEach(node => {
      node.classList.remove('col-selected');
    });
    wods.forEach(node => {
      node.classList.remove('day_selected');
    });
  }

  setNewSelectedColumn() {
    const colIndex = document.querySelector('[data-calendar-column]');
    colIndex.setAttribute('data-calendar-column', this.day);
  }

  select_day(e) {
    console.log("select day", e.target)
    this.removePreviousSelected();
    this.setNewSelectedColumn();
    const cells = document.querySelectorAll(
      '[data-day-day="' + this.day + '"]',
    );
    cells.forEach(node => {
      node.classList.add('col-selected');
    });
    this.wodTarget.classList.add('day_selected');
    // e.target.closest('.wod').classList.add('day_selected');
  }

  prevent(e) {
    // console.log("click ", e.target)
    // // e.stopImmediatePropagation();
    // // e.stopPropagation();
  }
  get week() {
    return this.data.has('week') ? this.data.get('week') : null;
  }

  get day() {
    return this.data.has('day') ? this.data.get('day') : null;
  }
}
