import {Controller} from 'stimulus';
import Tribute from "tributejs";
import Trix from 'trix';
import 'tributejs/tribute.css';

export default class extends Controller {
  static targets = [ 'textarea', 'exercises' ]

  connect () {
    this.editor =  this.textareaTarget.editor;
    this.textareaTarget.addEventListener('keydown', this.autosize.bind(this));
    // this.initTribute();
    this.initial_size();
    new Selectr(this.exercisesTarget, {
      placeholder: 'Add links videos',
      searchable: true,
      width: 200,
      customClass: 'exercises-links'
    });
  }

  disconnect () {
    this.tribute.detach(this.textareaTarget)
  }

  initTribute () {
    this.tribute =  new Tribute({
      allowSpaces: true,
      lookup: 'name',
      values: this.fetchExercises,
    })
    this.tribute.attach(this.textareaTarget);
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this);
    this.textareaTarget.addEventListener("tribute-replaced", this.replaced);
  }

  replaced (e) {
    let exercise = e.detail.item.original;
    let attachment = new Trix.Attachment({
      sgid: exercise.sgid,
      content: exercise.content
    })
    this.editor.insertAttachment(attachment);
    this.editor.insertString("");
  }

  fetchExercises (text, callback) {
    fetch(`/exercises.json?q[name_cont]=${text}`)
    .then( response => response.json())
    .then( exercises => callback(exercises))
    .catch(error => callback([]));
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition();
    this.editor.setSelectedRange([startPos+ (position - endPos), position])
    this.editor.deleteInDirection('backward');
  }

  autosize () {
    var el = this.textareaTarget;
    setTimeout(function(){
     el.style.cssText = 'height:' + el.scrollHeight + 'px';
    },0);
  }

  initial_size () {
    console.log("initial_size")
    this.textareaTarget.style.cssText='height: 200px';
  }

  disconnect () {
    this.textareaTarget.removeEventListener('keydown', this.autosize);
  }
}
