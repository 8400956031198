import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = [ 'select' ]

  connect () {
    new Selectr(this.selectTarget, {
      placeholder: 'Add links videos',
      searchable: true,
      width: 200,
      customClass: 'exercises-links'
    });
  }
}
